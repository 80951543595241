
.imagePreDiv {
    width: 100%;
    height: 400px;
}
.imageDiv {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 400px;
    border: 1px solid #5899ef;
    box-sizing: border-box;
    border-radius: 12px;
}
.imageDiv > a > img {
    max-width: 100%;
    max-height: 100%;
}
.imageDiv > img {
    max-width: 100%;
    max-height: 100%;
}
